<template>
  <div class="p-2">
    <h5 class="mt-2 mb-2">{{i18nT(`Interests`)}}</h5>
    <validation-observer ref="simpleRules">
      <div
        v-for="(interest, index) of interestData"
        :key="index"
      >
        <b-row>
          <b-col cols="12">
            <b-form-group :label="i18nT(`Interest`)" class="required">
              <validation-provider
                #default="{ errors }"
                name="Interest"
                rules="required"
              >
                <b-form-input
                  v-model="interest.Label"
                  :state="errors.length > 0 ? false:null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-form-group :label="i18nT(`Description`)">
              <b-form-textarea
                :placeholder="i18nT(`Briefly describe your abilities`)"
                v-model="interest.Description"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <div class="text-right">
          <b-button variant="outline-danger" class="mr-1" size="sm"
            @click="removeInterest">
            <feather-icon icon="XIcon" /> {{i18nT(`Remove`)}}
          </b-button>
        </div>
      </div>
    </validation-observer>

    <div>
      <b-button variant="outline-primary" class="mr-1" size="sm"
        @click="addNewInterest">
        <feather-icon icon="PlusCircleIcon" /> {{i18nT(`Add Another`)}}
      </b-button>
    </div>

    <h5 class="mt-2 mb-2">{{i18nT(`Other Optional Information`)}}</h5>
    <div>
      <div>
        <b-form-checkbox
          checked="false"
          name="check-button"
          switch
          v-model="relocate"
        >
            {{i18nT(`Are you willing to relocate for a job?`)}}
        </b-form-checkbox>
      </div>
      <div>
        <b-form-checkbox
          checked="true"
          name="check-button"
          switch
          v-model="travel"
        >
          {{i18nT(`Are you willing to travel?`)}}
        </b-form-checkbox>
      </div>
      <div>
        <b-form-checkbox
          checked="true"
          name="check-button"
          switch
          v-model="remote"
        >
        {{i18nT(`Do you accept remote working?`)}}
        </b-form-checkbox>
      </div>
    </div>
    <div class="text-right mt-2">
      <b-button
        variant="outline-primary"
        class="mr-1"
        @click="onPrevious"
      >
          {{i18nT(`Previous`)}}
      </b-button>
      <b-button
        variant="primary"
        @click="finish"
      >
          {{i18nT(`Next`)}}
      </b-button>
    </div>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BButton,
  BFormCheckbox
} from 'bootstrap-vue';
import Ripple from 'vue-ripple-directive';
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BButton,
    BFormCheckbox,
    ValidationProvider,
    ValidationObserver
  },
  directives: {
    Ripple,
  },
  props: {
    onFinish: {
      type: Function,
      default: null
    },
    onPrevious: {
      type: Function,
      default: null
    },
    interests: {
      type: Array,
      default: null
    },
    others: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      preivewImage: null,
      required,
      image: null,
      interestData: [],
      relocate: false,
      travel: false,
      remote: false,
    };
  },
  created() {},
  methods: {
    addNewInterest() {
      this.interestData.push({})
    },
    removeInterest(index) {
      this.interestData.splice(index, 1)
    },
    finish() {
      this.$refs.simpleRules.validate().then(success => {
        if(success) {
          const formData = new FormData()
          for(let i = 0; i < this.interestData.length; i++) {
            if(this.interestData[i].Id) {
              formData.append(`interests_data[${i}][id]`, this.interestData[i].Id)
            }
            formData.append(`interests_data[${i}][Title]`, this.interestData[i].Label)
            formData.append(`interests_data[${i}][Description]`, this.interestData[i].Description)
          }

          formData.append('Relocate', this.relocate ? 1: 0)
          formData.append('Travel', this.travel ? 1: 0)
          formData.append('Remote', this.remote ? 1: 0)

          this.$http.post(
            'candidates/profileInterestsOther', formData
          ).then((res) => {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: this.successMessage(res),
                icon: 'InfoIcon',
                variant: 'success',
              },
            })

            this.onFinish()
          })
          .catch((ex) => {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: this.errorMessage(ex),
                icon: 'InfoIcon',
                variant: 'danger',
              },
            })
          })
        }
      })
    }
  },
  watch: {
    interests: function(val) {
      this.interestData = val
    },
    others: function(val) {
      this.relocate = val.Relocate === 1
      this.travel = val.Travel === 1
      this.remote = val.Remote === 1
    }
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/quill.scss';
@import '@core/scss/vue/pages/page-blog.scss';

</style>
