<template>
  <div class="p-2">
    <h5 class="mt-2 mb-2">{{i18nT(`Education`)}}</h5>
    <b-row style="margin: 0">
      <validation-observer ref="simpleRules">
        <div
          v-for="(edu, index) of educationHistory"
          :key="index"
        >
          <b-row>
            <b-col cols="6">
              <b-form-group
                :label="i18nT(`Course`)" class="required"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Course"
                  rules="required"
                >
                  <b-form-input
                    v-model="edu.Course"
                    :state="errors.length > 0 ? false:null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group
                :label="i18nT(`Institution`)" class="required"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Institution"
                  rules="required"
                >
                  <b-form-input
                    v-model="edu.Institution"
                    :state="errors.length > 0 ? false:null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group
                :label="i18nT(`Start Date`)" class="required"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Start Date"
                  rules="required"
                >
                    <date-picker
                        v-model="edu.StartDate"
                        :max="edu.EndDate"
                        :start-weekday="1"
                        :locale="locale"
                        :date-format-options="{ year: 'numeric', month: 'short', day: '2-digit', weekday: 'short' }"
                    />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group
                :label="i18nT(`End Date`)" class="required"
              >
                <validation-provider
                  #default="{ errors }"
                  name="End Date"
                  rules="required"
                >
                    <date-picker
                        v-model="edu.EndDate"
                        :min="edu.StartDate"
                        :start-weekday="1"
                        :locale="locale"
                        :date-format-options="{ year: 'numeric', month: 'short', day: '2-digit', weekday: 'short' }"
                    />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col cols="12">
              <b-form-group
                :label="i18nT(`Description`)"
              >
                <b-form-textarea
                  v-model="edu.Description"
                  :placeholder="i18nT(`Briefly describe your abilities`)"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <div class="text-right">
            <b-button
              variant="outline-danger"
              class="mr-1"
              size="sm"
              @click="removeEdu(index)"
            >
              <feather-icon icon="XIcon" /> {{i18nT(`Remove`)}}
            </b-button>
          </div>
        </div>
      </validation-observer>

    </b-row>

    <div>
      <b-button
        variant="outline-primary"
        class="mr-1"
        size="sm"
        @click="addNewEdu"
      >
        <feather-icon icon="PlusCircleIcon" />
          {{i18nT(`Add Education`)}}
      </b-button>
    </div>

    <div class="text-right mt-2">
      <b-button
        variant="outline-primary"
        class="mr-1"
        @click="onPrevious"
      >
          {{i18nT(`Previous`)}}
      </b-button>
      <b-button
        variant="primary"
        @click="finish"
      >
        {{i18nT(`Next`)}}
      </b-button>
    </div>
  </div>
</template>

<script>

import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import DatePicker from '@core/components/DatePicker.vue'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BButton,
    ValidationProvider,
    ValidationObserver,
    DatePicker
  },
  props: {
    onFinish: {
      type: Function,
      default: null
    },
    onPrevious: {
      type: Function,
      default: null
    },
    educations: {
      type: Array,
      default: null
    },
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      preivewImage: null,
      image: null,
      required,
      educationHistory: [],
      locale: this.$i18n.locale,
    }
  },
  created() {
  },
  methods: {
    addNewEdu() {
      this.educationHistory.push({})
    },
    removeEdu(index) {
      this.educationHistory.splice(index, 1)
    },
    finish() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          const formData = new FormData()
          for(let i = 0; i < this.educationHistory.length; i++) {
            if(this.educationHistory[i].Id) {
              formData.append(`education_data[${i}][id]`, this.educationHistory[i].Id)
            }
            formData.append(`education_data[${i}][Course]`, this.educationHistory[i].Course)
            formData.append(`education_data[${i}][Institution]`, this.educationHistory[i].Institution)
            formData.append(`education_data[${i}][StartDate]`, this.educationHistory[i].StartDate)
            formData.append(`education_data[${i}][EndDate]`, this.educationHistory[i].EndDate)
            formData.append(`education_data[${i}][Description]`, this.educationHistory[i].Description)
          }
          this.$http.post(
            'candidates/profileEducation', formData
          ).then((res) => {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: this.successMessage(res),
                icon: 'InfoIcon',
                variant: 'success',
              },
            })

            this.onFinish()
          })
          .catch((ex) => {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: this.errorMessage(ex),
                icon: 'InfoIcon',
                variant: 'danger',
              },
            })
          })
        }
      })
    }
  },
  watch: {
    educations: function(val) {
      this.educationHistory = val
    }
  }
}
</script>

<style lang="scss">

@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/quill.scss";
@import "@core/scss/vue/pages/page-blog.scss";

</style>
