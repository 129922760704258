<template>
  <div class="p-2">
    <div class="text-center">
      <feather-icon
        icon="ThumbsUpIcon"
        size="64"
      />
      <h1>{{i18nT(`Thank you!`)}}</h1>
      <h3>{{i18nT(`Your profile is now complete.`)}} <b-link :to="{name: 'ca-resume'}">{{i18nT(`Check it out`)}}</b-link>
      </h3>
    </div>
    <div class="text-right mt-2">
      <b-button
        variant="outline-primary"
        class="mr-1"
        @click="onPrevious"
      >
        Previous
      </b-button>
      <b-button
        variant="primary"
        @click="onFinish"
      >
        Finish
      </b-button>
    </div>
  </div>
</template>

<script>
import { BButton, BLink } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BButton,
    BLink
  },
  props: {
    onFinish: {
      type: Function,
      default: null
    },
    onPrevious: {
      type: Function,
      default: null
    }
  },
  directives: {
    Ripple
  },
  created() {},
  methods: {},
  watch: {}
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/quill.scss';
@import '@core/scss/vue/pages/page-blog.scss';
</style>
