<template>
  <div>
    <div>
      <b-tabs v-model="activeTab">
        <b-tab
          :title="i18nT(`Basic Information`)"
          :active="activeTab === 0"
        >
          <b-card no-body>
            <basic-settings
              :onFinish="onPageUp"
              :onPrevious="onPageDown"
              :profile="profile.freelancer"
            />
          </b-card>
        </b-tab>
        <b-tab
          :title="i18nT(`Work Experience`)"
          :active="activeTab === 1"
        >
          <b-card no-body>
            <work-experience
              :onFinish="onPageUp"
              :onPrevious="onPageDown"
              :jobs="profile.jobs"
              :profile="profile.freelancer"
            />
          </b-card>
        </b-tab>
        <b-tab
          :title="i18nT(`Education`)"
          :active="activeTab === 2"
        >
          <b-card no-body>
            <education
              :onFinish="onPageUp"
              :onPrevious="onPageDown"
              :educations="profile.education"
            />
          </b-card>
        </b-tab>
        <b-tab
          :title="i18nT(`Qualifications`)"
          :active="activeTab === 3"
        >
          <b-card no-body>
            <qualifications
              :onFinish="onPageUp"
              :onPrevious="onPageDown"
              :skillOptions="skillOptions"
              :skills="profile.skills"
              :langOptions="languageOptions"
              :langs="profile.languages"
              :certs="profile.certificates"
            />
          </b-card>
        </b-tab>
        <b-tab
          :title="i18nT(`References`)"
          :active="activeTab === 4"
        >
          <b-card no-body>
            <references
              :onFinish="onPageUp"
              :onPrevious="onPageDown"
              :references="profile.references"
            />
          </b-card>
        </b-tab>
        <b-tab
          :title="i18nT(`Interests And Other`)"
          :active="activeTab === 5"
        >
          <b-card no-body>
            <interests
              :onFinish="onPageUp"
              :onPrevious="onPageDown"
              :interests="profile.interests"
              :others="profile.freelancer"
            />
          </b-card>
        </b-tab>
        <b-tab
          :title="i18nT(`Done`)"
          :active="activeTab === 6"
        >
          <b-card no-body>
            <done
              :onFinish="onPageUp"
              :onPrevious="onPageDown"
            />
          </b-card>
        </b-tab>
      </b-tabs>
    </div>
  </div>
</template>

<script>
import { BTabs, BTab, BCard } from 'bootstrap-vue'
import BasicSettings from './Basic'
import WorkExperience from './WorkExperience'
import Education from './Education'
import Qualifications from './Qualifications'
import References from './References'
import Interests from './Interests'
import Done from './Done'
import { dictToSelectArray } from '@/@core/utils/utils'

export default {
  components: {
    BTabs,
    BTab,
    BCard,
    BasicSettings,
    WorkExperience,
    Education,
    Qualifications,
    References,
    Interests,
    Done
  },
  data() {
    return {
      activeTab: 0,
      profile: {},
      skillOptions: [],
      languageOptions: []
    }
  },
  created() {
    this.$http.get(`candidates/profile?full=1`).then(({ data }) => {
      this.profile = data.data
      this.skillOptions = dictToSelectArray(data.data.skill_levels)
      this.languageOptions = dictToSelectArray(data.data.language_levels)
    })
  },
  methods: {
    onPageDown() {
      if (this.activeTab > 0) this.activeTab = this.activeTab - 1
    },
    onPageUp() {
      if (this.activeTab < 6) this.activeTab = this.activeTab + 1
      else if (this.activeTab === 6) {
        this.$router.push({ name: 'ca-resume' })
      }
    }
  }
}
</script>
