<template>
  <div class="p-2">    
    <validation-observer ref="simpleRules">
      <b-card>
        <b-card-body>
          <b-row>
            <b-row class="border-bottom ">
            <b-col
                cols="12"
                md="3"
            >
                <h5 class="mt-2">
                    {{i18nT(`Basic information`)}}
                </h5>
            </b-col>
              <b-col 
                cols="12"
                md="9">
                <b-col>
                  <avatar-control
                    :profileData="profileData"
                    :parent-id="profileData.Id"
                    @setNewAvatar="onSetNewAvatar"
      
                ></avatar-control>
                </b-col>
                <b-row>
                    <div class="demo-inline-spacing pl-1 mb-2">
                        <b-form-radio
                            v-model="profileData.Gender"
                            value="female"
                        >
                            {{i18nT(`Female`)}}
                        </b-form-radio>
                        <b-form-radio
                            v-model="profileData.Gender"
                            value="male"
                        >
                            {{i18nT(`Male`)}}
                        </b-form-radio>
                    </div>
                </b-row>
                <b-row>
                  <b-col cols="6">
                    <b-form-group
                      :label="i18nT(`First Name`)" class="required"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="First Name"
                        rules="required"
                      >
                        <b-form-input
                          v-model="profileData.FirstName"
                          :state="errors.length > 0 ? false:null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col cols="6">
                    <b-form-group
                      :label="i18nT(`Last Name`)" class="required"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Last Name"
                        rules="required"
                      >
                        <b-form-input
                          v-model="profileData.LastName"
                          :state="errors.length > 0 ? false:null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col cols="6">
                    <validation-provider
                        #default="{ errors }"
                        :name="i18nT(`First name`)"
                        rules="date_format"
                    >
                        <b-form-group
                            :label="i18nT(`Date of birth`)"
                        >
                            <date-picker
                                id="sk-bd-picker"
                                v-model="profileData.BirthDate"
                                reset-button
                                :manual-input="true"
                                :state="errors.length > 0 ? false : null"
                            />
                            <small class="text-danger">{{ i18nT(errors[0]) }}</small>
                        </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col cols="6">
                    <b-form-group
                      :label="i18nT(`Nationality`)"
                    >
                      <b-form-input
                        v-model="profileData.Nationality"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col cols="6">
                    <b-form-group
                      :label="i18nT(`Current Or Desired Position`)" 
                      label-for="jobPosition"
                      class="required"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Position"
                        rules="required"
                      >
                        <b-form-input
                          id="jobPosition"
                          v-model="profileData.Position"
                          :state="errors.length > 0 ? false:null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                    <b-col cols="6">
                        <b-form-group
                            :label="i18nT(`Social security number`)"
                        >
                            <b-form-input
                                v-model="profileData.SocialSecurity"
                            />
                        </b-form-group>
                    </b-col>


                    <b-col cols="6" xs="12">
                        <b-form-group
                            :label="i18nT(`Presentation`)"
                        >
                            <b-form-textarea
                                rows="5"
                                :placeholder="i18nT(`Briefly describe yourself and your career goals`)"
                                v-model="profileData.Presentation"
                            />
                        </b-form-group>
                    </b-col>
                    <b-col cols="6" xs="12">
                        <!-- CV SELECTOR -->
                        <b-form-group
                            :label="i18nT(`CV / Resume File`)"
                            class="pt-2"
                        >
                            <div>
                                <b-button
                                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                                    class="mt-0 mt-1 mr-1 mb-2"
                                    variant="outline-primary"
                                    @click="onFilePick('cv')"
                                >
                                    <feather-icon icon="PlusCircleIcon"/>
                                    {{ addCvBtnLabel }}
                                </b-button>

                                <b-form-file
                                    ref="cvfilePicker"
                                    v-model="apply_cv"
                                    class="hidden"
                                />
                                <p v-if="apply_cv || apply_cv_name" class="d-inline-block">

                                    <a v-if="cv_preview_link"
                                      class="d-inline-block"
                                      :href="cv_preview_link"
                                        target="_blank"
                                    >{{ apply_cv_name }}</a>
                                    <span
                                        class="d-inline-block"
                                        v-else>{{ apply_cv_name }}</span>
                                    <feather-icon icon="Trash2Icon"
                                              class="cursor-pointer ml-1"
                                              size="16" style="margin-right: 8px;"
                                              @click="onFileDelete('cv')"/>
                                </p>
                            </div>

                        </b-form-group>
                    </b-col>
                </b-row>
              </b-col>
            </b-row>

            <b-row  class="border-bottom">
              <b-col
                cols="12"
                md="3"
              >
                <h5 class="mt-2 mb-2">{{i18nT(`Contact Information`)}}</h5>
              </b-col>
              <b-col
                cols="12"
                md="9"
                class="mt-2"
              >
                <b-row>
                <b-col cols="6">
                  <b-form-group
                    :label="i18nT(`E-Mail`)" class="required"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Position"
                      rules="required"
                    >
                      <b-form-input
                        v-model="profileData.Email"
                        :state="errors.length > 0 ? false:null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col cols="6">
                  <b-form-group
                    :label="i18nT(`Phone Number`)"
                  >
                      <MazPhoneNumberInput
                          v-model="profileData.Phones"
                          :placeholder="i18nT(`Eg. +41-123-456-789`)"
                          :translations="{
                            countrySelectorLabel: i18nT(`Country code`),
                            countrySelectorError: i18nT(`Select a country`),
                            phoneNumberLabel: i18nT(`Phone number`),
                            example: i18nT(`Example:`),
                          }"
                      />
                  </b-form-group>
                </b-col>
                <b-col cols="6">
                  <b-form-group
                    :label="i18nT(`Address 1`)"
                  >
                    <b-form-input
                      v-model="profileData.Address1"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="6">
                  <b-form-group
                    :label="i18nT(`Address 2`)"
                  >
                    <b-form-input
                      v-model="profileData.Address2"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="6">
                  <b-form-group
                    :label="i18nT(`Zip / Postal Code`)"
                  >
                    <b-form-input
                      v-model="profileData.Zip"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="6">
                  <b-form-group
                    :label="i18nT(`State / Region`)"
                  >
                    <b-form-input
                      v-model="profileData.State"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="6">
                  <b-form-group
                    :label="i18nT(`City`)"
                  >
                    <b-form-input
                      v-model="profileData.City"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="6">
                  <b-form-group
                    :label="i18nT(`Country`)"
                  >
                    <b-form-select
                      v-model="profileData.Country"
                      :options="countries"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
              </b-col>
            </b-row>    

            <!-- SOCILA LINKS -->
              <b-col
                  cols="12"
                  md="3"
                  class="border-bottom"
              >
              <h5 class="mt-2">
                      {{ i18nT(`Social links`) }}
              </h5>
              </b-col>

              <b-col
                  cols="12"
                  md="9"
                  class="p-0 mt-1 border-bottom"
              >
                <div
                v-for="(social, index) in addedSocials"
                  :key="`social`+ index"
                      class="border-bottom pb-1 pt-1"
                  >
                  <b-row
                      >
                      <b-col cols="4" >
                          <b-form-group
                              :label="i18nT(`Social network`)"
                              label-for="Social network">
                              <v-select
                                  v-model="social.network"
                                  :options="availableSocials"
                                  :clearable="false"
                                  @input="disableSocial"
                                  label="text"
                                  :selectable="selected => selected.disabled !== true"
                              ></v-select>
                          </b-form-group>
                      </b-col>
                      <b-col
                              cols="8"
                              md="8"
                          >
                              <b-form-group
                                  :label="i18nT(`Social link`)"
                                  label-for="Social link"
                              >
                                  <b-form-input
                                      :placeholder="i18nT(`Social link`)"
                                      v-model="social.link"
                                  />
                              </b-form-group>
                          </b-col>
                  </b-row>
                  <div class="text-right">
                          <b-button
                              variant="outline-danger"
                              @click="removeSocials(index)"
                          >
                              <feather-icon icon="MinusCircleIcon" />
                              {{i18nT(`Remove`)}}
                          </b-button>
                      </div>
                    </div>
              <div class="pt-1 mb-2">
                      <b-button
                          variant="outline-primary"
                          @click="addSocials"
                      >
                          <feather-icon icon="PlusCircleIcon" />
                          {{i18nT(`Add more`)}}
                      </b-button>
                  </div>
              </b-col>
            <!-- <div>
            <h5 class="mt-2 mb-2">{{i18nT(`Additional Information`)}}</h5>
            <b-row>
              <b-col cols="6">
                <b-form-group
                  :label="i18nT(`Linkedin`)"
                >
                  <b-form-input
                    v-model="profileData.Linkedin"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="6">
                <b-form-group
                  :label="i18nT(`Web`)"
                >
                  <b-form-input
                    v-model="profileData.Web"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="6">
                <b-form-group
                  :label="i18nT(`Youtube`)"
                >
                  <b-form-input
                    v-model="profileData.Youtube"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="6">
                <b-form-group
                  :label="i18nT(`Twitter`)"
                >
                  <b-form-input
                    v-model="profileData.Twiter"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="6">
                <b-form-group
                  :label="i18nT(`Instagram`)"
                >
                  <b-form-input
                    v-model="profileData.Instagram"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="6">
                <b-form-group
                  :label="i18nT(`Behance`)"
                >
                  <b-form-input
                    v-model="profileData.Behance"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="6">
                <b-form-group
                  :label="i18nT(`Dribbble`)"
                >
                  <b-form-input
                    v-model="profileData.Dribble"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="6">
                <b-form-group
                  :label="i18nT(`Github`)"
                >
                  <b-form-input
                    v-model="profileData.Github"
                  />
                </b-form-group>
              </b-col>            
            </b-row>
          </div> -->

            <!-- AVATAR -->


            <!-- <b-col md="3">
          <h5 class="mt-2 mb-2">{{i18nT(`Photo`)}}</h5>
          <b-img
            v-if="preivewImage || profileData.ImageUrl"
            :src="preivewImage || profileData.ImageUrl"
            thumbnail
            fluid
            class="w-100"
            alt="Background Image"
          />
          <div>
            <b-form-file
              v-model="image"
              ref="backgroundPicker"
              class="hidden"
            />
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-primary"
              class="mt-0 mt-1 mr-1 mb-2"
              size="sm"
              @click="onBackgroundPick"
            >
              {{i18nT(`Select Image`)}}
            </b-button>
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-primary"
              class="mt-0 mt-1 mb-2"
              size="sm"
              @click="onBackgroundDelete"
            >
              <feather-icon
                icon="Trash2Icon"
                class="mr-25"
              />
              <span>{{i18nT(`Delete`)}}</span>
            </b-button>
          </div>
        </b-col> -->
          </b-row>
        </b-card-body>
      </b-card>
      
      <div class="text-right mt-2">
        <b-button
          variant="outline-primary"
          class="mr-1"
          @click="onPrevious"
        >
          {{i18nT(`Previous`)}}
        </b-button>
        <b-button
          variant="primary"
          @click="onSave"
        >
          {{i18nT(`Save`)}}
        </b-button>            
      </div>
    </validation-observer>
  </div>
</template>

<script>

import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BFormTextarea,
  BButton,
  BImg,
  BFormRadio,
  BFormFile,
  BCard,
  BCardBody
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate'
import { required } from '@validations'
import moment from 'moment';
import vSelect from 'vue-select'
import AvatarControl from '@/views/components/avatar/AvatarControl.vue'
import {dictToSelectArray} from "@core/utils/utils";
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import DatePicker from '@core/components/DatePicker.vue'

extend('date_format', {
    validate(value) {
    return {
      required: true,
      valid: moment(value, this.dateFormat).isValid() && value.length === 10 && moment(value).isBetween('1900-01-01', '2999-12-31')
    };
  },
  message: `Invalid date.`,
})

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BFormTextarea,
    BButton,
    BImg,
    BFormRadio,
    BFormFile,    
    ValidationProvider,
    ValidationObserver,
    DatePicker,
    vSelect,
    AvatarControl
  },
  props: {
    onFinish: {
      type: Function,
      default: null
    },
    onPrevious: {
      type: Function,
      default: null
    },
    profile: {
      type: Object,
      default: null
    },
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      UserImage: null,
      UserImageUrl: null,
      genderOptions: [
        {
          value: 'male',
          text: 'Male'
        },
        {
          value: 'female',
          text: 'Female'
        },
      ],
      availableSocials: [
          {value: "Twitter", text: this.i18nT(`Twitter`), disabled: false},
          {value: "Web", text: this.i18nT(`Website`), disabled: false},
          {value: "Behance", text: this.i18nT(`Behance`), disabled: false},
          {value: "Linkedin", text: this.i18nT(`Linkedin`), disabled: false},
          {value: "Dribble", text: this.i18nT(`Dribble`), disabled: false},
          {value: "Github", text: this.i18nT(`Github`), disabled: false},
          {value: "Youtube", text: this.i18nT(`Youtube`), disabled: false},
          {value: "Instagram", text: this.i18nT(`Instagram`), disabled: false},
          {value: "Tiktok", text: this.i18nT(`TikTok`), disabled: false}
      ],
      addedSocials: [{}],
      profileData: {},
      countries: [],
      apply_cv: null,
      apply_cv_name: '',
      cv_preview_link: null,
      apply_letter: null,
      apply_letter_name: '',
      required
    }
  },
  created() {
    this.$http.get(
      `system/countries`
    ).then(({data}) => {
      this.countries = dictToSelectArray(data.data)
    })
  },
  methods: {
    addSocials() {
        this.addedSocials.push({})
    },
    disableSocial() {
        this.availableSocials.forEach(soc => soc.disabled = false)

        this.addedSocials.map(social => {
            for (let i = 0; i < this.availableSocials.length; i++) {
                if(social.network && social.network.value && this.availableSocials[i].value === social.network.value) {
                    this.availableSocials[i].disabled = true
                }
            } 
        })
    },
    onSetNewAvatar(newData) {
        this.UserImage = newData.newUserImage;
        this.UserImageUrl = newData.newImageUrl;
        this.profileData = newData.newProfileData;
    },
    removeSocials(index) {
        this.addedSocials.splice(index, 1)
    },
      onFilePick: function (type) {
          if (type == 'cv') {
              this.$refs['cvfilePicker'].$refs.input.click()
          } else {
              this.$refs['letterfilePicker'].$refs.input.click()
          }
      },
      onFileDelete: function (type) {
          if (type == 'cv') {
              this.apply_cv = null
              this.apply_cv_name = ''
          } else {
              this.apply_letter = null
              this.apply_letter_name = ''
          }
      },
        onBackgroundPick: function() {
          this.$refs['backgroundPicker'].$refs.input.click()
        },
        onBackgroundDelete: function() {},
        onSave: function() {
          this.$refs.simpleRules.validate().then(success => {
            if (success) {
              const formData = new FormData()

              formData.append('FirstName', this.profileData.FirstName)
              formData.append('LastName', this.profileData.LastName)
              formData.append('Gender', this.profileData.Gender)
              formData.append('Position', this.profileData.Position)
              formData.append('Email', this.profileData.Email)
              formData.append('BirthDate', this.profileData.BirthDate)


              formData.append('Nationality', this.profileData.Nationality)
              formData.append('SocialSecurity', this.profileData.SocialSecurity)
              formData.append('Presentation', this.profileData.Presentation)

              formData.append('Phones', this.profileData.Phones)
              formData.append('Address1', this.profileData.Address1)
              formData.append('Address2', this.profileData.Address2)
              formData.append('Zip', this.profileData.Zip)
              formData.append('State', this.profileData.State)
              formData.append('City', this.profileData.City)
              formData.append('Country', this.profileData.Country)
              
              for(let i = 0; i < this.addedSocials.length; i++) {
                  if(this.addedSocials[i] && this.addedSocials[i].network) {
                      formData.append(this.addedSocials[i].network.value, this.addedSocials[i].link)
                  }
              }

              if(this.UserImage) {
                formData.append('user_image', this.UserImage)
              }

              if(this.apply_cv) {
                formData.append('user_cv', this.apply_cv)
              }

              if(!this.apply_cv_name){
                formData.append('user_cv_id', 0);
              }

              this.$http.post(
                'candidates/profileBasicInfo', formData
              ).then((res) => {
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: this.successMessage(res),
                    icon: 'InfoIcon',
                    variant: 'success',
                  },
                })

                this.onFinish()
              })
              .catch((ex) => {
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: this.errorMessage(ex),
                    icon: 'InfoIcon',
                    variant: 'danger',
                  },
                })
              })
            }
          })
        }
  },
    computed: {
        addCvBtnLabel() {
            if(this.apply_cv_name){
                return this.i18nT(`Replace file`);
            } else {
                return this.i18nT(`Add file`);
            }
        },
    },
  watch: {
      apply_cv: function (val) {
          if (val) {
              this.apply_cv = val
              this.apply_cv_name = val.name
          } else {
              this.apply_cv = null
              this.apply_cv_name = ''
          }
          this.cv_preview_link = null
      },
      apply_letter: function (val) {
          if (val) {
              this.apply_letter = val
              this.apply_letter_name = val.name
          } else {
              this.apply_letter = null
              this.apply_letter_name = ''
          }
      },
      UserImage: function(val) {
      this.UserImageUrl = URL.createObjectURL(val)
    },
    profile: function(val) {
      this.profileData = val

      this.availableSocials.forEach(social => {
          if(this.profileData[social.value]) {
              if(!this.addedSocials[0].network) {
                  this.addedSocials = []
              }
              this.addedSocials.push({
                  network: social,
                  link: this.profileData[social.value]
              })
              social.disabled = true
          }
      })
      if(!this.apply_cv_name && this.profileData.Cv && this.profileData.CvObj.Filename) {
          this.apply_cv_name = this.profileData.CvObj.Label
          this.cv_preview_link = this.profileData.CvObj.DownloadUrl
      }
    }
  }
}
</script>

<style>
#sk-bd-picker div[role="dialog"] {
    z-index: 90;
}
</style>
