<template>
    <div class="p-2">
        <h5 class="mt-2 mb-2">{{i18nT(`Work Experience`)}}</h5>
        <b-row class="m-2">
            <b-col
                v-for="experience in experienceOptions"
                :key="experience.value"
                class="m-0 p-0 stage-container"
            >
                <b-button
                    class="w-100 h-100 p-0 pt-1 pb-1"
                    @click.prevent="numOfExp=experience.value"
                    v-bind:class="[ numOfExp==experience.value ? 'active-stage': '']"
                    variant="outline-secondary"
                >
                    <span class="count">{{ experience.text }}</span> <br />
                    <span class="stage-label">{{i18nT(`YEARS`)}}</span>
                </b-button>
            </b-col>

        </b-row>

        <b-row style="margin: 0">
            <validation-observer ref="simpleRules">
                <div
                    v-for="(exp, index) of workExperience"
                    :key="index"
                >
                    <b-row >
                        <b-col cols="6">
                            <b-form-group
                                :label="i18nT(`Job title`)"
                                class="required"
                            >
                                <validation-provider
                                    #default="{ errors }"
                                    name="Job Title"
                                    rules="required"
                                >
                                    <b-form-input
                                        v-model="exp.Label"
                                        :state="errors.length > 0 ? false:null"
                                    />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-form-group>
                        </b-col>
                        <b-col cols="6">
                            <b-form-group
                                :label="i18nT(`Company`)"
                                class="required"
                            >
                                <validation-provider
                                    #default="{ errors }"
                                    name="Company"
                                    rules="required"
                                >
                                    <b-form-input
                                        v-model="exp.CompanyName"
                                        :state="errors.length > 0 ? false:null"
                                    />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-form-group>
                        </b-col>
                        <b-col cols="6">
                            <b-form-group
                                :label="i18nT(`Start Date`)"
                            >
                                <validation-provider
                                    #default="{ errors }"
                                    name="Start Date"
                                    rules="required"
                                >
                                    <date-picker
                                        id="invoice-datepicker"
                                        v-model="exp.StartDate"
                                        :max="exp.EndDate"
                                        :start-weekday="1"
                                        :locale="locale"
                                        :date-format-options="{ year: 'numeric', month: 'short', day: '2-digit', weekday: 'short' }"
                                        :state="errors.length > 0 ? false:null"
                                        :placeholder="i18nT(`Sep 2017`)"
                                    />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-form-group>
                        </b-col>
                        <b-col cols="6">
                            <b-form-group
                                :label="i18nT(`End Date`)"
                            >
                                <validation-provider
                                    #default="{ errors }"
                                    name="End Date"
                                    rules="required"
                                >
                                    <date-picker
                                        id="invoice-datepicker"
                                        v-model="exp.EndDate"
                                        :min="exp.StartDate"
                                        :start-weekday="1"
                                        :locale="locale"
                                        :date-format-options="{ year: 'numeric', month: 'short', day: '2-digit', weekday: 'short' }"
                                        :state="errors.length > 0 ? false:null"
                                        :placeholder="i18nT(`Sep 2018`)"
                                    />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-form-group>
                        </b-col>

                        <b-col cols="12">
                            <b-form-group
                                :label="i18nT(`Description`)"
                            >
                                <b-form-textarea
                                    v-model="exp.Description"
                                    :placeholder="i18nT(`Briefly describe your abilities`)"
                                />
                            </b-form-group>
                        </b-col>
                    </b-row>
                    <div class="text-right">
                        <b-button
                            variant="outline-danger"
                            class="mr-1"
                            size="sm"
                            @click="removeExp(index)"
                        >
                            <feather-icon icon="XIcon" /> {{i18nT(`Remove`)}}
                        </b-button>
                    </div>
                </div>
            </validation-observer>
        </b-row>

        <div>
            <b-button
                variant="outline-primary"
                class="mr-1"
                size="sm"
                @click="addNewExp"
            >
                <feather-icon icon="PlusIcon" />
                {{i18nT(`Add Work Experience`)}}

            </b-button>
        </div>

        <div class="text-right mt-2">
            <b-button
                variant="outline-primary"
                class="mr-1"
                @click="onPrevious"
            >
                {{i18nT(`Previous`)}}
            </b-button>
            <b-button
                variant="primary"
                @click="finish"
            >
                {{i18nT(`Next`)}}
            </b-button>
        </div>
    </div>
</template>

<script>

import {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import moment from 'moment'
import DatePicker from '@core/components/DatePicker.vue'

export default {
    components: {
        BRow,
        BCol,
        BFormGroup,
        BFormInput,
        BFormTextarea,
        BButton,
        ValidationProvider,
        ValidationObserver,
        DatePicker
    },
    props: {
        onFinish: {
            type: Function,
            default: null
        },
        onPrevious: {
            type: Function,
            default: null
        },
        jobs: {
            type: Array,
            default: null
        },
        profile: {
            type: Object,
            default: null
        }
    },
    directives: {
        Ripple,
    },
    data() {
        return {
            required,
            workExperience: [],
            experienceOptions: [{
                value: 0,
                text: '0-1'
            },{
                value: 1,
                text: '1-2'
            },{
                value: 2,
                text: '2-4'
            },{
                value: 3,
                text: '4-6'
            },{
                value: 4,
                text: '6 +'
            },
            ],
            numOfExp: 0
        }
    },
    computed: {
        locale() {
            return this.$store.state.app.locale
        }
    },
    created() {
    },
    methods: {
        addNewExp() {
            this.workExperience.push({})
        },
        removeExp(index) {
            this.workExperience.splice(index, 1)
        },
        finish() {
            this.$refs.simpleRules.validate().then(success => {
                if (success) {
                    const formData = new FormData()

                    formData.append('Experience', this.numOfExp)
                    const payload = this.workExperience.map(exp => ({
                        ...exp,
                        JobTitle: exp.Label,
                        Company: exp.CompanyName
                    }))

                    for(let i = 0; i < payload.length; i++) {
                        Object.keys(payload[i]).forEach(key => {
                            formData.append(`work_exp_data[${i}][${key == 'Id' ? 'id' : key}]`, payload[i][key])
                        })
                    }

                    this.$http.post(
                        'candidates/profileWorkExperience', formData
                    ).then((res) => {
                        this.$toast({
                            component: ToastificationContent,
                            position: 'top-right',
                            props: {
                                title: this.successMessage(res),
                                icon: 'InfoIcon',
                                variant: 'success',
                            },
                        })

                        this.onFinish()
                    })
                        .catch((ex) => {
                            this.$toast({
                                component: ToastificationContent,
                                position: 'top-right',
                                props: {
                                    title: this.errorMessage(ex),
                                    icon: 'InfoIcon',
                                    variant: 'danger',
                                },
                            })
                        })
                }
            })
            // this.onFinish()
        }
    },
    watch: {
        jobs: function(val) {
            this.workExperience = val.map(valcomp => ({
                ...valcomp,
                StartDate: moment(new Date(valcomp.StartDate)).format("MMM YYYY"),
                EndDate: moment(new Date(valcomp.EndDate)).format("MMM YYYY"),
            }))
        },
        profile: function(val) {
            if(val) {
                this.numOfExp = val.Experience
            }
        }
    }
}
</script>

<style lang="scss">

@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/quill.scss";
@import "@core/scss/vue/pages/page-blog.scss";

.stage-container {
    background: white;
    border: 1px solid gray;
    border-right: 0px;
    overflow: hidden;

    button {
        border: none !important;
        border-radius: 0px;
    }
    button:focus,
    button:active {
        background-color: $primary !important;
    }

    .count {
        font-size: 32px;
        color: #5e5873;
        font-weight: 300;
    }

    .stage-label {
        color: #5e5873;
        font-size: 10px;
    }

    .stage-avatar {
        color: white;
        width: 14px;
        height: 14px;
        background: #5e5873;
        display: inline-block;
        margin-right: 4px;
        padding-top: 1px;
        font-size: 10px;
    }
}

.stage-container:first-child {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
}

.stage-container:last-child {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    border-right: solid 1px gray;
}

.active-stage {
    background-color: $primary !important;
    span {
        color: white !important;
    }
}

</style>
