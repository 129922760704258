<template>
  <div class="p-2">
    <h5 class="mt-2 mb-2">{{i18nT(`References`)}}</h5>
    <p>Optional information. provide reference letters or contact details of people for reference.</p>
    
    <validation-observer ref="simpleRules">
      <div
        v-for="(refer, index) of referenceData"          
        :key="index"
      >
        <b-form-group
          :label="i18nT(`Reference`)"
        >
          <validation-provider
            #default="{ errors }"
            name="Reference"
            rules="required"
          >
            <b-form-input
              v-model="refer.Label"
              :state="errors.length > 0 ? false:null"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>           
        <b-tabs>
          <b-tab active>
            <template #title>
              <feather-icon icon="UploadCloudIcon" size="18" style="margin-right: 8px;"/>
              <span>{{i18nT(`FILE`)}}</span>
            </template>
            <div>
              <div>
                <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-primary"
                  class="mt-0 mt-1 mr-1 mb-2"
                  size="sm"
                  @click="onFilePick(index)"
                >
                    {{i18nT(`Select file`)}}
                </b-button>
                <b-form-file
                  v-model="file"
                  :ref="`filePicker-${index}`"
                  class="hidden"
                />
                <span v-if="refer.NewFile || (refer.AttachedFile && refer.AttachedFile.Label)">
                  {{refer.FileName || refer.AttachedFile.Label}}
                  <feather-icon icon="Trash2Icon" size="16" style="margin-right: 8px;" @click="onFileDelete(index)"/>

                </span>
              </div>
            </div>
          </b-tab>
          <b-tab>
            <template #title>
              <feather-icon icon="LinkIcon" size="18" style="margin-right: 8px;"/>
              <span>{{i18nT(`URL`)}}</span>
            </template>
            <div>
              <b-input-group>
                <template #prepend>
                  <b-input-group-text >
                    <feather-icon icon="LinkIcon" size="18" />
                  </b-input-group-text>
                </template>
                <b-form-input :placeholder="i18nT(`URL`)" v-model="refer.Url"/>
              </b-input-group>
            </div>
          </b-tab>
        </b-tabs>
        <b-form-group
          :label="i18nT(`Description`)"
        >
          <b-form-input
            v-model="refer.Description"
          />
        </b-form-group>
        <div class="text-right">
          <b-button
            variant="outline-danger"
            class="mr-1"
            size="sm"
            @click="removeReference(index)"
          >
            <feather-icon icon="XIcon" /> {{i18nT(`Remove`)}}
          </b-button>
        </div>
      </div>
    </validation-observer>
    
    <div>
      <b-button
        variant="outline-primary"
        class="mr-1"
        size="sm"
        @click="addReference"
      >
        <feather-icon icon="PlusIcon" /> {{i18nT(`Add Reference`)}}
      </b-button>
    </div>
    
    <div class="text-right mt-2">
      <b-button
        variant="outline-primary"
        class="mr-1"
        @click="onPrevious"
      >
          {{i18nT(`Previous`)}}
      </b-button>
      <b-button
        variant="primary"
        @click="finish"
      >
          {{i18nT(`Next`)}}
      </b-button>            
    </div>
  </div>
</template>

<script>

import {
  BFormGroup,
  BFormInput,
  BButton,
  BTabs,
  BTab,
  BFormFile,
  BInputGroup,
  BInputGroupText,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BFormGroup,
    BFormInput,
    BButton,
    BTabs,
    BTab,
    BFormFile,
    BInputGroup,
    BInputGroupText,
    ValidationProvider,
    ValidationObserver
  },
  directives: {
    Ripple,
  },
  props: {
    onFinish: {
      type: Function,
      default: null
    },
    onPrevious: {
      type: Function,
      default: null
    },
    references: {
      type: Array,
      default: null
    }
  },
  data() {
    return {
      required,
      referenceData: [],
      fileSelectIndex: 0,
      file: null
    }
  },
  created() {
  },
  methods: {
    onFilePick: function(index) {
      this.$refs[`filePicker-${index}`][0].$refs.input.click()
      this.fileSelectIndex = index
    },
    onFileDelete: function(index) {
      this.referenceData[index].NewFile = null
      this.referenceData[index].FileName = ''
      if (this.referenceData[index].AttachedFile) {
        this.referenceData[index].AttachedFile.Label = ''
        this.referenceData[index].AttachedFile.remove = true
      }
    },
    addReference: function() {
      this.referenceData.push({})
    },
    removeReference: function(index) {
      this.referenceData.splice(index, 1)
    },
    finish: function() {
      this.$refs.simpleRules.validate().then(success => {
        if(success) {
          const formData = new FormData()
          for(let i = 0; i < this.referenceData.length; i++) {
            if(this.referenceData[i].Id) {
              formData.append(`references_data[${i}][id]`, this.referenceData[i].Id)
            }
            formData.append(`references_data[${i}][Title]`, this.referenceData[i].Label)
            if(this.referenceData[i].Url) {
              formData.append(`references_data[${i}][Url]`, this.referenceData[i].Url)
            }
            formData.append(`references_data[${i}][Description]`, this.referenceData[i].Description)
            if(this.referenceData[i].NewFile) {
              formData.append(`references_data[${i}][file]`, this.referenceData[i].NewFile)
            }
            if(this.referenceData[i].AttachedFile && this.referenceData[i].AttachedFile.remove) {
              formData.append(`references_data[${i}][remove_file]`, 1)
            }
          }

          this.$http.post(
            'candidates/profileReferences', formData
          ).then((res) => {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: this.successMessage(res),
                icon: 'InfoIcon',
                variant: 'success',
              },
            })

            this.onFinish()
          })
          .catch((ex) => {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: this.errorMessage(ex),
                icon: 'InfoIcon',
                variant: 'danger',
              },
            })
          })
        }
      })
    }
  },
  watch: {
    references: function(val) {
      this.referenceData = val
    },
    file: function(val) {
      if(val) {
        this.referenceData[this.fileSelectIndex].NewFile = val
        this.referenceData[this.fileSelectIndex].FileName = val.name
      }
      else {
        this.referenceData[this.fileSelectIndex].NewFile = null
        this.referenceData[this.fileSelectIndex].FileName = ''
      }
    },
  },
}
</script>

<style lang="scss">

@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/quill.scss";
@import "@core/scss/vue/pages/page-blog.scss";

</style>
