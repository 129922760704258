<template>
  <div class="p-2">
    <h5 class="mt-2 mb-2">{{i18nT(`Professional Skills`)}}</h5>
    <validation-observer ref="simpleRules">
      <div
        v-for="(skill, index) of skillsData"
        :key="`skill-${index}`"
      >
        <b-row>
          <b-col cols="6">
            <b-form-group :label="i18nT(`Skill`)" class="required">
              <validation-provider
                #default="{ errors }"
                name="Skill"
                rules="required"
              >
                <b-form-input
                  v-model="skill.Skill"
                  :state="errors.length > 0 ? false:null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group :label="i18nT(`Level`)" class="required">
              <validation-provider
                #default="{ errors }"
                name="Level"
                rules="required"
              >
                <b-form-select
                  v-model="skill.LevelId"
                  :options="skillOptions"
                  :state="errors.length > 0 ? false:null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <div class="text-right">
          <b-button
            variant="outline-danger"
            class="mr-1"
            size="sm"
            @click="removeSkill(index)"
          >
            <feather-icon icon="XIcon" /> {{i18nT(`Remove`)}}
          </b-button>
        </div>
      </div>
      <div>
        <b-button
          variant="outline-primary"
          class="mr-1"
          size="sm"
          @click="addSkill"
        >
          <feather-icon icon="PlusIcon" /> {{i18nT(`Add Skill`)}}
        </b-button>
      </div>
      <h5 class="mt-2 mb-2">{{i18nT(`Languages`)}}</h5>
      <div
        v-for="(lang, index) of langData"
        :key="`lang-${index}`"
      >
        <b-row>
          <b-col cols="4">
            <b-form-group :label="i18nT(`Language`)" class="required">
              <validation-provider
                #default="{ errors }"
                name="Language"
                rules="required"
              >
                <b-form-input
                  v-model="lang.Language"
                  :state="errors.length > 0 ? false:null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="4">
            <b-form-group :label="i18nT(`Write`)" class="required">
              <validation-provider
                #default="{ errors }"
                name="Level"
                rules="required"
              >
                <b-form-select
                  v-model="lang.WriteId"
                  :options="langOptions"
                  :state="errors.length > 0 ? false:null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="4">
            <b-form-group :label="i18nT(`Speak`)" class="required">
              <validation-provider
                #default="{ errors }"
                name="Level"
                rules="required"
              >
                <b-form-select
                  v-model="lang.SpeakId"
                  :options="langOptions"
                  :state="errors.length > 0 ? false:null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <div class="text-right">
          <b-button
            variant="outline-danger"
            class="mr-1"
            size="sm"
            @click="removeLang(index)"
          >
            <feather-icon icon="XIcon" /> {{i18nT(`Remove`)}}
          </b-button>
        </div>
      </div>
      <div>
        <b-button
          variant="outline-primary"
          class="mr-1"
          size="sm"
          @click="addLang"
        >
          <feather-icon icon="PlusIcon" /> {{i18nT(`Add Language`)}}
        </b-button>
      </div>
      <h5 class="mt-2 mb-2">{{i18nT(`Certificates`)}}</h5>
      <div
        v-for="(cert, index) of certData"
        :key="`cert-${index}`"
      >
        <b-form-group :label="i18nT(`Certification`)">
          <validation-provider
            #default="{ errors }"
            name="Certification"
            rules="required"
          >
            <b-form-input
              v-model="cert.Label"
              :state="errors.length > 0 ? false:null"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
        <b-tabs>
          <b-tab active>
            <template #title>
              <feather-icon
                icon="UploadCloudIcon"
                size="18"
                style="margin-right: 8px;"
              />
              <span>{{i18nT(`FILE`)}}</span>
            </template>
            <div>
              <div>
                <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-primary"
                  class="mt-0 mt-1 mr-1 mb-2"
                  size="sm"
                  @click="onFilePick(index)"
                >
                  {{i18nT(`Select file`)}}
                </b-button>
                <b-form-file
                  v-model="file"
                  :ref="`filePicker-${index}`"
                  class="hidden"
                />
                <span v-if="cert.NewFile">
                  {{cert.FileName}}
                  <feather-icon
                    icon="Trash2Icon"
                    size="16"
                    style="margin-right: 8px;"
                    @click="onFileDelete(index)"
                  />
                </span>
              </div>
              <p>{{i18nT(`Attach images, text files, spreadsheets, pdf documents and videos.`)}}</p>
            </div>
          </b-tab>
          <b-tab>
            <template #title>
              <feather-icon
                icon="LinkIcon"
                size="18"
                style="margin-right: 8px;"
              />
              <span>{{i18nT(`URL`)}}</span>
            </template>
            <div>
              <b-input-group>
                <template #prepend>
                  <b-input-group-text>
                    <feather-icon
                      icon="LinkIcon"
                      size="18"
                    />
                  </b-input-group-text>
                </template>
                <b-form-input
                  :placeholder="i18nT(`URL`)"
                  v-model="cert.Url"
                />
              </b-input-group>
              <p class="pt-2">Enter a link to images, text files, spreadsheets, pdf documents and videos. </p>
            </div>
          </b-tab>
        </b-tabs>
        <b-link
          v-if="cert.AttachedFile"
          :href="cert.AttachedFile.DownloadUrl"
        >
          {{cert.AttachedFile.Label}}
        </b-link>
        <b-form-group :label="i18nT(`Description`)">
          <b-form-input v-model="cert.Description" />
        </b-form-group>
        <div class="text-right">
          <b-button
            variant="outline-danger"
            class="mr-1"
            size="sm"
            @click="removeCert(index)"
          >
            <feather-icon icon="XIcon" /> {{i18nT(`Remove`)}}
          </b-button>
        </div>
      </div>
      <div>
        <b-button
          variant="outline-primary"
          class="mr-1"
          size="sm"
          @click="addCert"
        >
          <feather-icon icon="PlusIcon" /> {{i18nT(`Add Certificate`)}}
        </b-button>
      </div>
    </validation-observer>

    <div class="text-right mt-2">
      <b-button
        variant="outline-primary"
        class="mr-1"
        @click="onPrevious"
      >
          {{i18nT(`Previous`)}}
      </b-button>
      <b-button
        variant="primary"
        @click="finish"
      >
          {{i18nT(`Next`)}}
      </b-button>
    </div>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BButton,
  BFormSelect,
  BTabs,
  BTab,
  BFormFile,
  BInputGroup,
  BInputGroupText,
  BLink
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BButton,
    BFormSelect,
    BTabs,
    BTab,
    BFormFile,
    BInputGroup,
    BInputGroupText,
    ValidationProvider,
    ValidationObserver,
    BLink
  },
  directives: {
    Ripple
  },
  props: {
    onFinish: {
      type: Function,
      default: null
    },
    onPrevious: {
      type: Function,
      default: null
    },
    skills: {
      type: Array,
      default: null
    },
    skillOptions: {
      type: Array,
      default: null
    },
    langs: {
      type: Array,
      default: null
    },
    langOptions: {
      type: Array,
      default: null
    },
    certs: {
      type: Array,
      default: null
    }
  },
  data() {
    return {
      required,
      file: null,
      fileName: null,
      Url: '',
      skillsData: [],
      langData: [],
      certData: [],
      fileSelectIndex: 0
    }
  },
  created() {},
  methods: {
    onFilePick: function(index) {
      this.$refs[`filePicker-${index}`][0].$refs.input.click()
      this.fileSelectIndex = index
    },
    onFileDelete: function(index) {
      this.certData[index].NewFile = null
      this.certData[index].FileName = ''
    },
    removeSkill: function(index) {
      this.skillsData.splice(index, 1)
    },
    addSkill: function() {
      this.skillsData.push({ Skill: '', LevelId: '1' })
    },
    removeLang: function(index) {
      this.langData.splice(index, 1)
    },
    addLang: function() {
      this.langData.push({
        WriteId: '1',
        SpeakId: '1'
      })
    },
    removeCert: function(index) {
      this.certData.splice(index, 1)
    },
    addCert: function() {
      this.certData.push({})
    },
    finish: function() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          const formData = new FormData()
          for (let i = 0; i < this.skillsData.length; i++) {
            if (this.skillsData[i].Id) {
              formData.append(`skills_data[${i}][id]`, this.skillsData[i].Id)
            }
            formData.append(
              `skills_data[${i}][Skill]`,
              this.skillsData[i].Skill
            )
            formData.append(
              `skills_data[${i}][Level]`,
              this.skillsData[i].LevelId
            )
          }

          for (let i = 0; i < this.langData.length; i++) {
            if (this.langData[i].Id) {
              formData.append(`languages_data[${i}][id]`, this.langData[i].Id)
            }
            formData.append(
              `languages_data[${i}][Language]`,
              this.langData[i].Language
            )
            formData.append(
              `languages_data[${i}][Write]`,
              this.langData[i].WriteId
            )
            formData.append(
              `languages_data[${i}][Speak]`,
              this.langData[i].SpeakId
            )
          }

          for (let i = 0; i < this.certData.length; i++) {
            if (this.certData[i].Id) {
              formData.append(
                `certificates_data[${i}][id]`,
                this.certData[i].Id
              )
            }
            formData.append(
              `certificates_data[${i}][Title]`,
              this.certData[i].Label
            )
            if (this.certData[i].Url) {
              formData.append(
                `certificates_data[${i}][Url]`,
                this.certData[i].Url
              )
            }
            formData.append(
              `certificates_data[${i}][Description]`,
              this.certData[i].Description
            )
            if (this.certData[i].NewFile) {
              formData.append(
                `certificates_data[${i}][file]`,
                this.certData[i].NewFile
              )
            }
          }

          this.$http
            .post('candidates/profileQualifications', formData)
            .then(res => {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: this.successMessage(res),
                  icon: 'InfoIcon',
                  variant: 'success'
                }
              })

              this.onFinish()
            })
            .catch(ex => {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: this.errorMessage(ex),
                  icon: 'InfoIcon',
                  variant: 'danger'
                }
              })
            })
        }
      })
    }
  },
  watch: {
    file: function(val) {
      if (val) {
        this.certData[this.fileSelectIndex].NewFile = val
        this.certData[this.fileSelectIndex].FileName = val.name
      } else {
        this.certData[this.fileSelectIndex].NewFile = null
        this.certData[this.fileSelectIndex].FileName = ''
      }
    },
    skills: function(val) {
      for(const skill in val) {
        this.skillsData.push({
          Id: val[skill].Id,
          Skill: val[skill].Skill,
          LevelId: val[skill].LevelId || 1
        })
      }
      // if (val.length > 0)
      //   this.skillsData = val.map(skill => ({
      //     ...skill,
      //     LevelId: skill.LevelId ? skill.LevelId : '1'
      //   }))
    },
    langs: function(val) {
      this.langData = val
      console.log('langData: ', this.langData)
    },
    certs: function(val) {
      this.certData = val
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/quill.scss';
@import '@core/scss/vue/pages/page-blog.scss';
</style>
